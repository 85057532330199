/* Built In Imports */
/* External Imports */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra,
    Heading,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the FaqSectionGroupV2 component.
 *
 * @param {Array} faqSection - Main FAQ data.
 * @param region
 * @param lang
 * @param bgColor
 * @param color
 * @returns {ReactElement} FaqSection component.
 */

const FaqSectionGroupV2 = ({ faqSection, region, lang, color }) => {
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="1.1rem"
      lineHeight="1.61"
      width="100%"
      mx="auto"
      mt="0"
      mb="0"
      px="15px"
      py="80px"
      id={faqSection?.id}
      className="faqSection"
      maxW="1330px"
      position="relative"
    >
      <Box
        pos="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        zIndex={0}
        _before={{
          content: "''",
          pos: 'absolute',
          top: 0,
          height: { base: '68px', md: '94px' },
          left: '0',
          right: 0,
          background:
            'linear-gradient(180deg, rgba(213, 202, 174, 0.5) 0%, rgba(255, 255, 255, 0) 50%)',
          backgroundBlendMode: 'multiply',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
          opacity: '0.8',
        }}
        _after={{
          content: "''",
          pos: 'absolute',
          bottom: '0',
          height: { base: '90px', md: '214px' },
          left: '0',
          right: 0,
          background:
            'linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 50%)',
          backgroundBlendMode: 'multiply',
          transform: 'matrix(1, 0, 0, -1, 0, 0)',
          opacity: '0.8',
        }}
      ></Box>
      <Box position="relative" zIndex={2}>
        <Heading
          as="h3"
          maxW="676px"
          fontWeight="500"
          fontSize={{ base: '26px', md: '32px' }}
          mb="40px"
          mx="auto"
          color="#823333"
        >
          {faqSection.title}
        </Heading>
        <Accordion
          key={nanoid()}
          allowMultiple
          m={{ base: '0 auto' }}
          width={{ base: '100%', md: '100%' }}
          maxW="770px"
        >
          {faqSection?.faqSections?.length
            ? faqSection.faqSections.map(section => {
                return (
                  <AccordionItem border="none" key={nanoid()}>
                    <AccordionButton
                      bgColor={'none'}
                      borderRadius="0"
                      mb="2px"
                      fontWeight="600"
                      fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                      fontSize="20px"
                      borderBottom="1.5px solid #65000F"
                      padding={{
                        base: '10px 0 10px',
                        md: '10px 45px 10px 13px',
                      }}
                      color={color || 'white'}
                      _focus={{ shadow: 'transparent' }}
                      minHeight="55px"
                      lineHeight="1.5"
                    >
                      <chakra.div
                        fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                        flex="1"
                        maxW={{ md: '555px' }}
                        marginLeft={{ base: '0', md: '45.5px' }}
                        w="100%"
                        textAlign="left"
                        color="#65000F"
                        textTransform="uppercase"
                      >
                        {section.title}
                      </chakra.div>
                      <AccordionIcon
                        color="#65000F"
                        marginLeft={{ md: '95px' }}
                      />
                    </AccordionButton>
                    <AccordionPanel pb={1} px={{ base: '0', md: '1' }}>
                      {section?.faqs?.length
                        ? section.faqs.map(faq => {
                            return (
                              <FaqChildren
                                key={nanoid()}
                                faq={faq}
                                region={region}
                                lang={lang}
                              />
                            );
                          })
                        : null}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })
            : null}
        </Accordion>
      </Box>

      <style>
        {`
        .commanFaqs {
          margin-top:0;
        }
        .commanFaqs div > div {
          margin-top:0;
        }
        
          .commanFaqs p {
            padding-bottom:25px;
            padding-left:25px;
          }
          .commanFaqs ol, .commanFaqs ul  {
            margin-left:20px;
          }   
        `}
      </style>
      <style global jsx>
        {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }
          .faq p {
            padding-bottom: 20px;
          }
        `}
      </style>
    </Box>
  );
};

const FaqChildren = ({ faq, region, lang }) => {
  return (
    <Box border="none">
      <AccordionItem
        key={nanoid(4)}
        borderBottomWidth="1px"
        borderBottomColor="#CAC0A8"
        borderTop="none"
        lineHeight="1.61"
        className="faqSectionItem"
        maxW={{ base: 'auto', lg: '698px', md: '698px' }}
        w={{ md: '92%' }}
        mx="auto"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              pb="0.8rem"
              fontWeight="600"
              fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
              fontSize={{ base: '16px', md: '20px' }}
              maxW={698}
              borderTop="none"
              color="#fff"
              //  marginLeft={{md:'20px'}}
              padding={{ base: '20px 0', md: '20px 10px 22px 20px' }}
              _hover={{ background: 'none' }}
              _focus={{ shadow: 'transparent' }}
            >
              <chakra.div
                flex="1"
                w="100%"
                textAlign="left"
                maxW={555}
                mx="auto"
                pl={{ base: '0', md: '10px' }}
                // key={nanoid(4)}
                color="#121212"
              >
                {faq.question}
              </chakra.div>

              {isExpanded ? (
                <AccordionIcon
                  color="#000000"
                  w="25px"
                  h="25px"
                  marginLeft={{ base: '100px', md: '75px' }}
                  transform={{ base: 'rotate(0deg)', md: 'rotate(180deg)' }}
                />
              ) : (
                <AccordionIcon
                  color="#000000"
                  w="25px"
                  h="25px"
                  marginLeft={{ base: '100px', md: '75px' }}
                  transform={{ base: 'rotate(-90deg)', md: 'rotate(0deg)' }}
                />
              )}
            </AccordionButton>

            <AccordionPanel
              pb={4}
              pt="0"
              fontSize={{ base: '16px', md: '20px' }}
              fontFamily="FedraSansStd-book"
              lineHeight="1.61"
              // padding={{ base: '15px 0', md: '15px 30px' }}
              px={{ base: '0', md: '30px' }}
              marginLeft={{ sm: '0px', md: '35px' }}
            >
              <Box
                display="block"
                width="100%"
                lineHeight="1.61"
                fontSize={{  sm: '16px',lg: '18px', }}
                color="#28231e"
              >
                <Box maxW={{ md: '615px' }} minW="251px">
                  <StructuredTextParser
                    mt="0"
                    px={{ base: '0', md: '16px' }}
                    str={render(faq.answer)}
                    region={region}
                    lang={lang}
                    color="#49423B"
                  />
                </Box>
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Box>
  );
};

export default FaqSectionGroupV2;

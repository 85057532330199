/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import StructuredTextContent from '@lb/Common/StructuredTextContent';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import StructuredTextParser from '../Utility/StructuredTextParser';

/* Styles */
/* Services */

/**
 * Renders Offering Group Component
 *
 * @param {object} sectionContent
 * @param region
 * @param lang
 * @returns {ReactElement} Offering Group component
 */

const OfferingGroup = ({ sectionContent, region, lang }) => {
  return (
    <>
      <Box
        maxW="1330px"
        w="100%"
        margin="auto"
        background={`url('${config.staticPath}/d/46272/1680116967-offering-bg.jpg')`}
        pt={{ base: '40px', md: '65px' }}
        pb={{ base: '60px', md: '60px' }}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        position="relative"
      >
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          blendMode='soft-light'
          background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)"
        ></Box>
        <Box maxW="1210px" margin="auto">
          <Flex 
            width="100%"
            flexWrap="wrap"
            style={{ gap: '25px' }}
            alignItems={{ base: 'flex-start', md: 'end' }}
            mt="-30px"
            mb="30px"
            padding={{ base: '16px', md: '16px', lg: '14px', xl: 0 }}
           // flexDirection={{ md: 'row', xl: 'row', lg: 'row' }}
          >
            
            <Box 
              w={{ md: '37%', xl: '36%',lg:'42%'}}
              maxW={{ xxl: '322px' }}
              mb={{xl:'20px',lg:'22px'}}
              pl={{ md: '0px',xl:'10px' }}
              mt={{xxl:'40px'}}
              className='OfferingHeading'
            >
              <Text
                color="title.light"
                as="h3"
                fontSize={{ base: 'h5', md: 'h3',xl:'h3' }}
                fontFamily="FedraSerifDisplay"
                lineHeight={{ base: '50px', md: '70px' }}
                fontWeight={600}
                ml={{md:'25px' }}
                textAlign={{ base: 'left', md: 'left', lg: 'left' }}
              >
                {sectionContent?.title}
              </Text>
            </Box>
            <Box 
              w={{ base: '100%', md: '58%',xl:'70%',lg:'55%' }}
              maxW="686px"
              pt={{ md: '10px', lg:'30px',base:'0px' }} 
              pl={{xxl:'25px',lg:'15px',xl:'25px',md:'10px'}}
              mt={{ base: '0px' }}
              mb={{md:'0px',lg:'30px',xl:'58px'}}
              ml={{md:'-15px'}}
              //ml={{lg:'20px',xxl:'20px',xl:'5px'}}
              className='offeringHDescription'
              fontSize={{xl:'20px'}}
            >
              <Text
                color="bodyText.color3"
                fontSize={{ base: '16px', md: '20px' }}
                fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                lineHeight={{ base: '24px', md: '32px' }}
                fontWeight={350}
                display="flex"
                alignItems="center"
              >
                {StructuredTextContent(sectionContent?.description)}
              </Text>
              <Text
                color="secondary.main"
                fontSize={{ base: 'body2', md: '20px' }}
                fontWeight={350}
                fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                fontStyle="italic"
                mt="10px"
                lineHeight={{ base: '29px', md: '29.3px' }}
              >
                {StructuredTextContent(sectionContent?.orangeWarningText)}
              </Text>
            </Box>
          </Flex>
          <Flex 
            mt={{md:"60px",base:'40px'}}
            mb="60px"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            style={{ gap: '16px' }}
            w={{xl:'100%'}}
            //margin='auto'
            //ml={{xl:'200px'}} mr={{xl:'-217px'}}
          >
            {sectionContent?.offerings?.map((card, key) => (
              <>
                <Box
                  key={nanoid()}
                  width={{ base: '100%', md: '240px',lg:'288px' }}
                  height={{ base: '275.28px', md: '265px' }}
                  pos="relative"
                  maxH={{ base: '265px', sm: '300px', md: '265px' }}
                  maxW="288px"
                  // padding={{ md: '5px' }}
                  // w={{ md: '30%' }}
                  // h={{ md: '40%' }}
                  backgroundImage={`url(${card?.image?.url})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  // mb={{ base: '10px', md: '0' }}
                  // background="blue"
                >
                  <Box
                    background="linear-gradient(349.95deg, rgba(0, 0, 0, 0.8) 24.67%, rgba(0, 0, 0, 0) 67.17%, rgba(0, 0, 0, 0.24) 92.31%)"
                    // h={{ base: '275.28px', md: '265px' }}
                    h="100%"
                    position="relative"
                    // _before={{
                    //   content: "''",
                    //   background:
                    //     'linear-gradient(349.95deg, rgba(0, 0, 0, 0.8) 26.67%, rgba(0, 0, 0, 0) 69.17%, rgba(0, 0, 0, 0.24) 92.31%)',
                    //   position: 'absolute',
                    //   top: '0',
                    //   bottom: 0,
                    //   right: 0,
                    //   left: 0,
                    //   zIndex: 999,
                    // }}
                  >
                    {/* <LazyLoadImageComponent
                       width="100%"
                       h="100%"
                       alt={card?.image?.alt}
                       objectFit="cover"
                     /> */}

                    <Flex 
                      flexDir="column"
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      h="100%"
                      // mt={{ xl: '-90px', md: '-110px', base: '-120px' }}
                    >
                      <Box height={{base:'120px', md:'115px'}}  mb={{md:'5px'}}>
                        <Text  pr='5px'
                          fontSize="20px"
                          pl="16px"
                          fontWeight="600"
                          color="#F8F8F8"
                          lineHeight="21px"
                          display="flex"
                          alignItems="center"
                        >
                          {card?.title}
                        </Text>
                        <Box 
                          mt={{ md: '-30px', base: '-10px' }}
                          p={{
                            md: '5px 15px 0px 15px',
                            base: '5px 5px 0 0px',
                          }}
                        >
                          <StructuredTextParser
                            str={render(card?.description)}
                            className="offeringsCard"
                          />
                          <style>
                            {`
                              .OfferingHeading p{
                                display: -webkit-box;
                                  -webkit-line-clamp: 2;
                                  -webkit-box-orient: vertical;
                                  overflow: hidden;
                                  font-weight:600;
                                  line-height:53px;
                              }

                              .offeringHDescription p{
                                display: -webkit-box;
                                  -webkit-line-clamp: 4;
                                  -webkit-box-orient: vertical;
                                  overflow: hidden;
                                  font-weight:350;
                                  line-height:33px;
                              }
                              .offeringsCard p{
                                display: -webkit-box;
                                  -webkit-line-clamp: 4;
                                  -webkit-box-orient: vertical;
                                  overflow: hidden;
                                  font-weight:400;
                                  line-height:19.6px;
                                  font-size:14px;
                                  font-family:FedraSansStd-Book;
                                  color:#F3F0E6;
                              }
                            `}
                          </style>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </>
            ))}
          </Flex>

          {sectionContent?.enableButton && (
            <Flex
              width="100%"
              h="auto"
              alignItems="center"
              justifyContent="center"
              marginTop={{ md: '61px', base: '40px' }}
            >
              <SimpleButton
                style={sectionContent?.button[0]}
                region={region}
                lang={lang}
              />
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OfferingGroup;

/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Flex, useBreakpointValue } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Styles */
/* Services */

/**
 * Renders BackIcon Component
 *
 * @param {string} background
 * @param {ReactElement} BackIcon Component
 */

const BackIcon = ({ background, region, language }) => {
  const router = useRouter();

  const handleClick = e => {
    const siteLinkTag = '/linga-bhairavi/';
    const url = router.asPath;

    e.preventDefault();
    let urls = sessionStorage.getItem('lb-url')?.split(',');

    if (urls?.length < 2) {
      if (url && urls[0]?.indexOf(siteLinkTag) > -1) {
        if (url.indexOf('/rituals/') > -1) {
          router.push(
            `${siteLinkTag}/${region}/${language}/rituals`,
            undefined,
            {
              shallow: true,
            }
          );
        } else if (url.indexOf('/sadhana/') > -1) {
          router.push(
            `${siteLinkTag}/${region}/${language}/sadhana`,
            undefined,
            {
              shallow: true,
            }
          );
        } else if (url.indexOf('/events/') > -1) {
          router.push(
            `${siteLinkTag}/${region}/${language}/events`,
            undefined,
            {
              shallow: true,
            }
          );
        } else if (url.indexOf('/bring-devi-home/') > -1) {
          router.push(
            `${siteLinkTag}/${region}/${language}/bring-devi-home`,
            undefined,
            {
              shallow: true,
            }
          );
        } else if (url.indexOf('/visit-devi/') > -1) {
          router.push(
            `${siteLinkTag}/${region}/${language}/visit-devi`,
            undefined,
            {
              shallow: true,
            }
          );
        } else if (url.indexOf('/article/') > -1) {
          router.push(`${siteLinkTag}/${region}/${language}/blog`, undefined, {
            shallow: true,
          });
        } else if (url.indexOf('/video/') > -1) {
          router.push(`${siteLinkTag}/${region}/${language}/blog`, undefined, {
            shallow: true,
          });
        }
      } else {
        router.back();
      }
    } else {
      router.back();
    }
  };
  return (
    <Flex
      width={{ base: '20px', md: '38px' }}
      height={{ base: '20px', md: '38px' }}
      background={background || '#A9392D'}
      rounded="full"
      justifyContent="center"
      alignItems="center"
      _hover={{ color: '#F6A15E' }}
      cursor="pointer"
      fontWeight="bold"
      fontSize="16px"
      onClick={handleClick}
      color="#F5D5A9"
      textAlign="center"
    >
      <LazyLoadImageComponent
        width={{ base: '26px', md: '11px' }}
        height={{ base: '16px', md: '11px' }}
        src={useBreakpointValue({
          base: `${config.imgPath}/d/46272/1681967036-vector.svg`,
          md: `${config.imgPath}/d/46272/1681967549-vector.svg`,
        })}
        alt="back"
      />
    </Flex>
  );
};

export default BackIcon;

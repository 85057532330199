/* Built In Imports */
/* External Imports */
import { Box, Flex, List, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import FlipCard from '@lb/components/Cards/FlipCard';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
/* Services */

/**
 * @param sectionContent
 */

const RelatedLBSadhanaBringDeviHome = ({
  sectionContent,
  region,
  lang,
  pageData,
  pType,
  section,
}) => {
  const cardArrowBgStyle = {
    homepage_sadhana_yellow: '#D09700',
    sadhana_yellow: '#D09700',
    rituals_bring_devi_home_red: '#BC4034',
    bring_devi_home_red: '#BC4034',
    events_green: '#366445',
    homepage_events_green: '#366445',
    homepage_rituals_red: '#BC4034',
  };
  // console.log(sectionContent?.style?.includes('homepage_'), 'style home page');
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="45%"
        zIndex="10"
        cursor="pointer"
        border="0"
        w="48px"
        h="48px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        right={{ xl: '50px', base: '3px' }}
        bgSize="13px"
        onClick={onClick}
        background={cardArrowBgStyle[sectionContent.style]}
        placeItems="center"
        display={{ base: 'none', md: 'grid' }}
        boxShadow="0px 28.8px 53.6px rgba(0, 0, 0, 0.2), 0px 18.6667px 31.3907px rgba(0, 0, 0, 0.151852), 0px 11.0933px 17.0726px rgba(0, 0, 0, 0.121481), 0px 5.76px 8.71px rgba(0, 0, 0, 0.1), 0px 2.34667px 4.36741px rgba(0, 0, 0, 0.0785185), 0px 0.533333px 2.10926px rgba(0, 0, 0, 0.0481481)"
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.6 15L15 8.63975M15 8.63975L8.6 2.2795M15 8.63975L2.2 8.63975"
            stroke="#ffffff"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        display={{ base: 'none', md: 'grid' }}
        top="45%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        background={cardArrowBgStyle[sectionContent.style]}
        placeItems="center"
        filter="drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.3))"
        border="0"
        w="48px"
        h="48px"
        textAlign="center"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{ xl: '-25px', base: '-13px' }}
        onClick={onClick}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.4 2L2 8.36025M2 8.36025L8.4 14.7205M2 8.36025H14.8"
            stroke="#ffffff"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };
  const settingsSmall = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: false,
    slidesToShow:
      sectionContent?.cards?.length < 2 || sectionContent?.offerings?.length < 2
        ? 1
        : 2,
    appendDots: dots => (
      <Box padding="0 10px 10px" margin="-10px 0 13px 122px">
        <List
          style={{ margin: '20px 20px 30px 20px' }}
          display="flex"
          alignItems="flex-start"
          justifyContent="start"
        >
          {' '}
          {dots}{' '}
        </List>
      </Box>
    ),
    customPaging: i => (
      <Box
        bg="#9e2828"
        width="16px"
        margin="20px"
        rounded="full"
        height="16px"
      ></Box>
    ),

    slidesToScroll:
      sectionContent?.cards?.length < 2 || sectionContent?.offerings?.length < 2
        ? 1
        : 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // centerMode: true,
    // centerPadding: '24px 50px',
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow:
            sectionContent?.cards?.length < 2 ||
              sectionContent?.offerings?.length < 2
              ? 1
              : 2,
          slidesToScroll:
            sectionContent?.cards?.length < 2 ||
              sectionContent?.offerings?.length < 2
              ? 1
              : 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          initialSlide: 1,
          // centerPadding: '50px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerPadding: '5px',
        },
      },
    ],
  };

  const bgImage = {
    homepage_rituals_red: `url(${config.imgPath}/d/46272/1681226555-1681225992-group-10410.jpg)`,
    homepage_events_green: `${config.imgPath}/d/46272/1684382932-1681226743-group_10775_720.jpg`,
    homepage_sadhana_yellow: `${config.imgPath}/d/46272/1681127855-group-10881-2-1.jpg`,
    sadhana_yellow: `url(${config.imgPath}/d/46272/1681226555-1681225992-group-10410.jpg)`,
    rituals_bring_devi_home_red: `url(${config.imgPath}/d/46272/1681226555-1681225992-group-10410.jpg)`,
    bring_devi_home_red: `url(${config.imgPath}/d/46272/1681226555-1681225992-group-10410.jpg)`,
    events_green: `url(${config.imgPath}/d/46272/1681226555-1681225992-group-10410.jpg)`,
  };

  forEach(sectionContent?.cards, card => {
    forEach(pageData?.arpanam?.rituals, ritual => {
      if (ritual?.arpanamId === card?.arpanamId) {
        card.visible = true;
        if (!card?.overrideRegistrationLogic) {
          if (!ritual?.locations.length) {
            card.visible = false;
          } else {
            let locations = [];
            forEach(ritual?.locations, loc => {
              locations.push({ title: loc });
            });
            card.locations = locations;
            card.ritual = ritual;
            card.availableOnline = ritual?.isAvailableOnline;
          }
        }
      }
    });
  });

  return (
    <Box
      maxW={pType == 'home' ? '100%' : '1330px'}
      w="100%"
      h={pType == 'home' && section.viewH ? '100svh' : 'auto'}
      className="PromoteSlider"
      display="grid"
      placeItems="center"
      mx="auto"
    >
      <Box
        bgColor="rgba(243, 211, 175, 0.5)"
        bgSize="cover !important"
        overflow="hidden"
        h={pType == 'home' && section.viewH ? '100svh' : 'auto'}
        w="100%"
        mx="auto"
        bgImage={bgImage[sectionContent?.style]}
        backgroundRepeat="no-repeat"
        backgroundPosition={{ base: 'bottom', md: 'right' }}
        py={{
          base: pType == 'home' && section.viewH ? '0px' : '40px',
          md: pType == 'home' && section.viewH ? '0px' : '80px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          h={pType == 'home' && section.viewH ? '100%' : 'auto'}
        >
          <Box maxW="1300px" mx="auto" w="100%">
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              flexWrap={{ base: 'wrap', lg: 'wrap', xl: 'nowrap' }}
              justifyContent={{
                base: 'flex-start',
                md: 'flex-start',
                xl: 'space-between',
              }}
              alignItems={{
                base: 'flex-start',
                md: 'flex-start',
                xl: 'flex-start',
              }}
            >
              <Box
                w={{ base: '100%', lg: '100%', xl: '31%' }}
                mr={{ base: '3%', lg: '3%', xl: '3%' }}
                ml={{ base: '0', md: '0', xl: 'auto' }}
                maxW={{
                  base: 'auto',
                  md: 'auto',
                  xl: sectionContent?.style?.includes('homepage')
                    ? '380px'
                    : '285px',
                }}
              // overflow="hidden"
              >
                <Flex
                  flexDirection={{
                    base: 'column',
                    lg: 'column',
                    xl: 'column',
                  }}
                  width="100%"
                  h="100%"
                  alignItems={{
                    base: 'flex-start',
                    md: 'flex-start',
                    xl: 'flex-end',
                  }}
                  justifyContent={{
                    base: 'flex-start',
                    md: 'flex-start',
                    xl: 'flex-end',
                  }}
                  // float="left"
                  textAlign={{ base: 'left', lg: 'left', xl: 'right' }}
                >
                  <Box
                    alignSelf={{
                      base: 'flex-start',
                      md: 'flex-start',
                      lg: 'flex-start',
                      xl: 'flex-end',
                    }}
                    mb={{
                      base:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? `15px`
                          : '',
                      md: '0',
                    }}
                    bgImage={
                      sectionContent.style === 'homepage_sadhana_yellow'
                        ? `${config.imgPath}/d/46272/1683178686-pineapple.png`
                        : ''
                    }
                    bgSize="cover"
                    bgRepeat="no-repeat"
                    maxW={{ base: 'auto', md: 'auto', xl: '451px' }}
                    w={{
                      base: 'auto',
                      md: 'auto',
                      lg: 'auto',
                      xl:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '125%'
                          : 'auto',
                    }}
                    ml={{
                      base:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '0%'
                          : '0',
                      md:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '0%'
                          : '0',
                      lg:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '0%'
                          : '0',
                      xl:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '-22%'
                          : '0',
                    }}
                    maxH="auto"
                    padding={{
                      base:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '0 0 0 35px'
                          : '5px 15px',
                      md:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '0 0 0 35px'
                          : '5px 15px',
                      xl:
                        sectionContent.style === 'homepage_sadhana_yellow'
                          ? '22px 35px 22px 0'
                          : '16px 5px',
                    }}
                    // alignSelf="flex-end"
                    display="flex"
                    justifyContent={{
                      base: 'flex-end',
                      md: 'flex-end',
                      lg: 'flex-start',
                      xl: 'flex-end',
                    }}
                    alignItems={{
                      base: 'flex-end',
                      md: 'flex-end',
                      lg: 'flex-start',
                      xl: 'flex-end',
                    }}
                    minW="90px"
                    pb={{ base: '0', md: '0', xl: '10px' }}
                    flexDirection={{
                      base: 'row-reverse',
                      md: 'row-reverse',
                      lg: 'initial',
                    }}
                  >
                    {!sectionContent.style.includes('homepage') && (
                      <LazyLoadImageComponent
                        src={`${config.staticPath}/d/46272/1679654805-devi-website_illustrations.png`}
                        w="80px"
                        h="80px"
                      />
                    )}
                    <Text
                      as="h3"
                      fontSize={{ base: 'h5', md: 'h4', xl: 'h3' }}
                      textAlign={{ base: 'left', lg: 'left', xl: 'right' }}
                      color="#121212"
                      fontFamily="FedraSerifDisplay"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight={{ base: '52px', md: '70px' }}
                      pr={{
                        base: sectionContent?.style.includes('homepage_')
                          ? '22px'
                          : '',
                        md: sectionContent?.style.includes('homepage_')
                          ? '22px'
                          : '',
                        xl: sectionContent?.style.includes('homepage_')
                          ? '0'
                          : '',
                      }}
                      pl={{
                        base: sectionContent?.style.includes('homepage_')
                          ? '0'
                          : '',
                        md: sectionContent?.style.includes(
                          'homepage_sadhana_yellow'
                        )
                          ? '16px'
                          : '',
                        xl: sectionContent?.style.includes('homepage_')
                          ? '16px'
                          : '',
                      }}
                      maxW={{
                        base: 'auto',
                        md: 'auto',
                        lg: 'auto',
                        xl: sectionContent?.style.includes('homepage_')
                          ? '380px'
                          : '240px',
                      }}
                    >
                      {sectionContent?.title}
                    </Text>
                  </Box>
                  {/* <Box > */}
                  <StructuredTextParser
                    mt={{ base: '0', md: '0', xl: '15px' }}
                    maxW={{
                      base: 'auto',
                      md: 'auto',
                      xl: sectionContent?.style.includes('homepage_')
                        ? '341px'
                        : '284px',
                    }}
                    str={render(sectionContent.description)}
                    color="white"
                    id={
                      sectionContent?.style.includes('homepage_events_green') ||
                        sectionContent?.style.includes('homepage_rituals_red')
                        ? 'homepageSadhana'
                        : sectionContent?.style.includes(
                          'homepage_sadhana_yellow'
                        )
                          ? 'homepageSadhanaYellow'
                          : 'RelatedDescription'
                    }
                    lineHeight={{ base: '22px', md: '26px' }}
                    textAlign={sectionContent.style}
                    px={0}
                  />
                  {/* </Box> */}
                  <Box>
                    <style jsx global>
                      {`
                        #RelatedDescription {
                          padding: 0;
                        }
                        #homepageSadhanaYellow,
                        #homepageSadhana {
                          padding: 0;
                        }
                        #homepageSadhana {
                          font-size: 18px;
                        }
                        #homepageSadhanaYellow p {
                          color: #f8f8f8;
                          font-size: 18px;
                        }
                        .RelatedSlider .slick-dots li.slick-active div {
                          position: relative;
                        }
                        .RelatedSlider .slick-dots {
                          bottom: -40px !important;
                        }
                        .RelatedSlider .slick-list .slick-active {
                          width: 414px !important;
                        }
                        .RelatedSlider .slick-dots li.slick-active div::before {
                          content: '';
                          position: absolute;
                          top: -7px;
                          left: -7px;
                          border: 3px solid #ffbc00;
                          width: 30px;
                          height: 30px;
                          background: transparent;
                          transform: rotate(180deg);
                          border-radius: 100%;
                        }
                        @media (max-width: 1280px) {
                          #homepageSadhanaYellow,
                          #homepageSadhana {
                            padding: 0 16px;
                          }
                          #RelatedDescription {
                            padding: 0 16px;
                          }
                        }

                        @media only screen and (max-width: 767px) {
                          #homepageSadhanaYellow,
                          #homepageSadhana {
                            padding: 0 16px;
                          }
                          #RelatedDescription {
                            padding: 0 16px;
                          }
                          .RelatedSlider .slick-list .slick-active {
                            width: 310px !important;
                            margin-right: 14px;
                          }
                          #homepageSadhanaYellow p {
                            color: #f8f8f8;
                            font-size: 16px;
                          }
                          #homepageSadhana {
                            font-size: 18px;
                          }
                          .RelatedSlider .slick-dots {
                            bottom: -30px !important;
                          }
                          .RelatedSlider .slick-slider .slick-list {
                            padding-top: 0px;
                            padding-bottom: 0;
                            padding-right: 0px;
                            padding-left: 5px;
                          }
                        }
                        @media only screen and (min-width: 768px) and (max-width: 992px) {
                          .RelatedSlider .slick-list {
                            width: 96% !important;
                            margin-right: 14px;
                          }
                          .RelatedSlider .slick-list .slick-active {
                            width: 410px !important;
                          }
                          .RelatedSlider .slick-dots {
                            bottom: -30px !important;
                          }
                        }
                        @media only screen and (min-width: 993px) and (max-width: 1024px) {
                          .RelatedSlider .slick-list {
                            width: 96% !important;
                            margin-right: 14px;
                          }
                          .RelatedSlider .slick-list .slick-active {
                            width: 410px !important;
                            margin-right: 14px;
                          }
                        }

                        @media only screen and (max-width: 350px) {
                          .RelatedSlider .slick-list .slick-active {
                            width: 280px !important;
                            margin-right: 14px;
                          }
                        }
                        .RelatedSlider .slick-slider .slick-list {
                          padding-top: 0px;
                          padding-bottom: 50px;
                          padding-right: 0px;
                          padding-left: 0px;
                        }
                      `}
                    </style>
                  </Box>
                </Flex>
              </Box>
              <Box
                w={{ base: '100%', lg: '100%', xl: '67%' }}
                height="auto"
                // maxW="828px"
                className="RelatedSlider"
                position="relative"
                // maxW={{base:"auto",md:"auto",xl:"832px"}}

                pl={{ base: '16px', md: '16px', xl: '0' }}
                pt={{
                  base: pType == 'home' && section.viewH ? '0px' : '20px',
                  md: 0,
                }}
              >
                {/* <Slider> */}
                <Slider {...settingsSmall}>
                  {sectionContent?.cards
                    ? sectionContent?.cards?.map(card => {
                      return (
                        <FlipCard
                          section={sectionContent}
                          sectionContent={card}
                          key={nanoid()}
                          region={region}
                          lang={lang}
                          cardTitle= {pType == 'home'?"LB Home":sectionContent?.title}
                          maxWidth="380px"
                        />
                      );
                    })
                    : sectionContent?.offerings?.map(offerings => {
                      return (
                        <FlipCard
                          section={sectionContent}
                          sectionContent={offerings}
                          key={nanoid()}
                          region={region}
                          lang={lang}
                          cardTitle={pType == 'home'?"LB Home":sectionContent?.title}
                          maxWidth="380px"
                        />
                      );
                    })}
                </Slider>
                <Flex
                  alignItems={{ base: 'center', md: 'start' }}
                  justifyContent={{ base: 'center', md: 'start' }}
                  mt={{
                    base: pType == 'home' && section.viewH ? '20px' : '0px',
                    md: '0px',
                  }}
                  ml={{ base: '0', md: '93px', xl: '95px' }}
                >
                  <SimpleButton
                    style={
                      sectionContent?.cta
                        ? sectionContent?.cta[0]
                        : sectionContent?.button
                    }
                    region={region}
                    lang={lang}
                    mb="0"
                    mt={{ base: '0px', md: '30px', lg: '30px' }}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RelatedLBSadhanaBringDeviHome;

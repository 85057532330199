/* Built In Imports */
import { useState } from 'react';
import Slider from 'react-slick';

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Styles */
import classes from '@lb/components/Sliders/ritual/RGallery.module.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
/* Services */

/**
 * @param sectionContent
 */

const RGallery = ({ sectionContent }) => {
  const NextArrow = ({ onClick }) => {
    return (
      <Box
        onClick={onClick}
        pos="absolute"
        top="50%"
        right="20px"
        cursor="pointer"
        display={{ base: 'none', md: 'block' }}
      >
        <Box>
          <svg
            width="36"
            height="37"
            viewBox="0 0 36 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0001 11.4736L24.6667 18.1403M24.6667 18.1403L18.0001 24.807M24.6667 18.1403H11.3334M1.33342 18.1403C1.33342 27.345 8.79542 34.807 18.0001 34.807C27.2048 34.807 34.6667 27.345 34.6667 18.1403C34.6667 8.93555 27.2048 1.47363 18.0001 1.47363C8.79542 1.47363 1.33342 8.93555 1.33342 18.1403Z"
              stroke="white"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </Box>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <Box
        height="100%"
        onClick={onClick}
        pos="absolute"
        top="50%"
        left="-60px"
        zIndex={1}
        cursor="pointer"
        display={{ base: 'none', md: 'flex' }}
      >
        <Box>
          <svg
            width="36"
            height="37"
            viewBox="0 0 36 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9999 11.4736L11.3333 18.1403M11.3333 18.1403L17.9999 24.807M11.3333 18.1403H24.6666M34.6666 18.1403C34.6666 27.345 27.2046 34.807 17.9999 34.807C8.79517 34.807 1.33325 27.345 1.33325 18.1403C1.33325 8.93555 8.79517 1.47363 17.9999 1.47363C27.2046 1.47363 34.6666 8.93555 34.6666 18.1403Z"
              stroke="black"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      </Box>
    );
  };

  const [imgIndex, setImgIndex] = useState(0);
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    slidesPerRow: 1,
    centerMode: true,
    centerPadding: 40,
    useTransform: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImgIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: 15,
        },
      },
    ],
  };

  return sectionContent?.length || sectionContent?.length > 0 ? (
    <>
      <Box pos="relative">
        <Box
          width="100%"
          height="auto"
          maxW="956px"
          py="40px"
          pl={{ base: '16px', md: '70px', lg: '50px' }}
          mx="auto"
          display="block"
          boxSizing="border-box"
        >
          <Slider {...settings}>
            {!sectionContent.galleryType &&
              sectionContent?.map((image, index) => {
                return (
                  <Box
                    key={nanoid()}
                    className={
                      imgIndex === index
                        ? classes.slickCurrent
                        : classes.slickDeactive
                    }
                  >
                    <Box
                      w="100%"
                      height={{
                        base: '190px',
                        sm: '350px',
                        md: '400px',
                        lg: '500px',
                      }}
                      position="relative"
                    >
                      {imgIndex === index && (
                        <Box
                          w="100%"
                          position="absolute"
                          bottom={0}
                          right={0}
                          left={0}
                          display={{ base: 'none', md: 'none', lg: 'block' }}
                          height="100px"
                          background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)"
                        />
                      )}
                      <Image
                        src={image.url}
                        alt=""
                        width="100%"
                        height="100%"
                        objectFit="cover"
                      />
                    </Box>
                  </Box>
                );
              })}
          </Slider>
        </Box>
      </Box>
    </>
  ) : (
    <Box maxW="816px" m={{ base: '40px auto', md: '60px auto' }}>
      <Text
        color="headingText.main"
        fontSize={{ base: '26px', md: '32px' }}
        fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
        fontWeight="500"
        lineHeight={{ base: '27.82px', md: '43px' }}
        mb="20px"
        textAlign="left"
        fontStyle="normal"
      >
        {sectionContent.title}
      </Text>
      <Box maxH={{ base: '190px', md: '500px' }} w="100%" textAlign="center">
        {sectionContent?.heroImage?.url && (
          <LazyLoadImageComponent
            verticalAlign="middle"
            src={sectionContent?.heroImage?.url}
            alt={sectionContent?.heroImage?.alt}
            objectFit="cover"
            height={{ base: '190px', md: '500px' }}
            maxH={{ base: '190px', md: '500px' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default RGallery;

/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Image as ChakraImage } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */
/* Styles */

/**
 * Renders Progressive Load Image Component
 *
 * @param {string} src - Source of the image
 * @returns {ReactElement} Progressive Load Image Component component
 */
const ProgressiveImage = ({ placeholderSrc, src, ...props }) => {
  placeholderSrc = placeholderSrc || `${src}?blur=40`;
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const customClass =
    placeholderSrc && imgSrc === placeholderSrc ? 'loading' : 'loaded';

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <>
      <ChakraImage
        {...{ src: imgSrc, ...props }}
        className={`p-image ${customClass}`}
      />
      <style global jsx>
        {`
          .p-image.loading {
            filter: blur(2px);
            clip-path: inset(0);
          }
          .p-image.loaded {
            filter: blur(0px);
            transition: filter 0.5s linear;
          }
        `}
      </style>
    </>
  );
};

export default ProgressiveImage;

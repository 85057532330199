/* Built In Imports */
/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  chakra,
  Heading,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import MidContainer from '@lb/components/Layout/MidContainer';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Faq Expanded Section component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} FaqExpandedSection component
 */

const FaqExpandedSection = ({ sectionContent, region, lang }) => {
  return sectionContent ? (
    <Box w="100%" maxW="1330px" m="0 auto" position="relative">
      <Box
        pos="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        zIndex={0}
        _before={{
          content: "''",
          pos: 'absolute',
          top: 0,
          height: { base: '68px', md: '94px' },
          left: '0',
          right: 0,
          background:
            'linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 50%)',
          backgroundBlendMode: 'multiply',
          transform: 'matrix(-1, 0, 0, 1, 0, 0)',
          opacity: '0.8',
        }}
        _after={{
          content: "''",
          pos: 'absolute',
          bottom: '0',
          height: { base: '90px', md: '214px' },
          left: '0',
          right: 0,
          background:
            'linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 50%)',
          backgroundBlendMode: 'multiply',
          transform: 'matrix(1, 0, 0, -1, 0, 0)',
          opacity: '0.8',
        }}
      ></Box>
      <Box pos="relative" zIndex={2}>
        <MidContainer>
          <Box p={'0 10px'} py={{ base: '60px', md: '80px' }}>
            <Box maxW="698px" mx="auto" textAlign="left">
              <Heading
                as="h3"
                maxW="642px"
                fontWeight="500"
                fontSize="32px"
                mb="40px"
                color="#823333"
              >
                {sectionContent?.title}
              </Heading>

              <Accordion allowMultiple>
                {sectionContent?.faqs?.map((faq, ind) => {
                  return (
                    <AccordionItem
                      key={nanoid(4)}
                      borderBottomWidth="1px"
                      borderBottomColor={'gray.400'}
                      borderTop="none"
                      lineHeight="1.61"
                    >
                      <AccordionButton
                        px="0"
                        fontFamily="FedraSansStd-medium"
                        fontSize="1.1rem"
                        borderTop="none"
                        _hover={{ background: 'none' }}
                        _focus={{ shadow: 'transparent' }}
                      >
                        <chakra.div
                          flex="1"
                          textAlign="left"
                          key={nanoid(4)}
                          color="headingText.dark"
                          fontFamily="'FedraSerifAStdBook'"
                          fontWeight="bold"
                          py="14px"
                        >
                          {faq.question}
                        </chakra.div>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel
                        pb={2}
                        fontSize="1.1rem"
                        fontFamily="FedraSerifAStdBook"
                        lineHeight="1.61"
                        px="0"
                        pl={{ base: '0', md: '29px' }}
                      >
                        <Box
                          display="block"
                          width="100%"
                          lineHeight="1.61"
                          fontSize={{ lg: '18px', sm: '16px' }}
                          color="#28231e"
                          pb={{ base: '24px', md: '0' }}
                        >
                          <Box>
                            <StructuredTextParser
                              str={render(faq.answer)}
                              region={region}
                              lang={lang}
                              mt="0"
                              maxW="100%"
                              fontFamily="FedraSerifAStdBook"
                              fontSize="18px"
                              color="#49423B"
                            />
                          </Box>
                        </Box>
                      </AccordionPanel>
                      <style global jsx>
                        {`
                          .faq a {
                            color: #cf4520;
                          }
                          .faq span.setting:after {
                            font-family: fontawesome;
                            font-size: 1.6rem;
                            content: '\f013';
                            color: #fff;
                            background: rgba(31, 41, 55, var(--tw-bg-opacity));
                            border-radius: 18px;
                            padding: 7px 8px 6px;
                            --tw-bg-opacity: 1;
                            vertical-align: middle;
                          }
                          .faq ul {
                            padding-left: 1.5em;
                          }
                          .faq p {
                            padding-bottom: 20px;
                          }
                        `}
                      </style>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </Box>
          </Box>
        </MidContainer>
      </Box>
    </Box>
  ) : null;
};

export default FaqExpandedSection;

/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import ProgressiveImage from '@lb/components/UI/ProgressiveImage';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

/* Internal Imports */
/* Components */
/* Styles */
// import 'react-lazy-load-image-component/src/effects/blur.css';
/**
 *
 * @param {*} param0
 * @returns
 */
const ProBgImg = ({ children, alt, img, ishover,  ...props }) => {

  // const handleLoad = () => {
  //   setLoaded(true);
  // };

  return (
    <Box
      // background={
      //   `url(${img})`
      //   // 'linear-gradient(349.95deg, rgba(0, 0, 0, 0.64) 12.65%, rgba(42, 30, 0, 0) 56.89%, rgba(0, 0, 0, 0.24) 97.6%)'
      // }
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      // filter={loaded ? 'none' : 'blur(4px)'}
      transition="filter 0.5s"
      // onLoad={handleLoad}
      {...props}
      className="lb-flipcardimage"
      overflow="hidden"
      _hover={{
        bg: 'linear-gradient(349.95deg, rgba(0, 0, 0, 0.9) 12.65%, rgba(0, 0, 0, 0) 62.71%, rgba(0, 0, 0, 0.27) 92.31%)',
        bgBlendMode: 'multiply',bg: '#2121213D'
      }}
    >
      <ProgressiveImage
        pos="absolute"
        src={img}
        alt={alt}
        // onLoad={handleLoad}
        // height={0}
        loading="lazy"
        top={0}
        left={0}
        h="100%"
        w="100%"
        // clipPath="circle(0)"
        // display="none !important"
        // transform="scale(1)"
        // transition="transform .3s"
        sx={{
          '.lb-flipcardimage:hover &': {
            transform: 'scale(1.10)',
          },
        }}
      />
      <LazyLoadComponent>
        {children}
        {/* {children} */}
      </LazyLoadComponent>
      <style>
        {`
          .lb-flipcardimage {
            overflow: hidden;
            height: 450px;
          }
          .lb-flipcardimage img {
            transition: transform .5s ease;
          }
          .lb-flipcardimage img:hover {
            transform: 'scale(1.10)',
          }
          .p-image.loaded {
            transition: transform .3s ease-in;
          }
          @media (max-width: 768px) {
            .lb-flipcardimage {
              height: 374px !important;
            }
            .p-image.loaded {
              transition: transform .15s ease-in;
            }
          }
        `}
      </style>
    </Box>
  );
};
export default ProBgImg;
// const useProgressiveImage = src => {
//   const [sourceLoaded, setSourceLoaded] = useState(null);

//   useEffect(() => {
//     const img = new Image();
//     img.src = src;
//     img.onload = () => setSourceLoaded(src);
//   }, [src]);

//   return sourceLoaded;
// };
// const ProBgImg = ({children, placeholder = 'blur(20px)', img, ...props}) => {
//   const loaded = useProgressiveImage(img);

//   return (
//     <Box backgroundImage={`url(${loaded || placeholder})`} {...props}>
//       {children}
//     </Box>
//   );
// };

// export default ProBgImg;

/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { TiLocationOutline } from 'react-icons/ti';

/* Internal Imports */
/* Components */
import { FlagButton } from '@lb/components/Icons/lbArrowIcons';
import ProBgImg from '@lb/components/UI/ProBgImg';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
import classes from '@lb/components/Cards/FlipCard.module.css';

/* Mixpanel for SEO*/
import { mixPanel } from '@utils/Mixpanel';

/**
 *
 * @param flipCardBack flip card back side
 * @param flipCardFront flip card front
 * @param sectionContent
 * @param section
 * @param summaryColor
 * @param region
 * @param lang
 * @param cardTitle
 */

const FlipCard = ({
  flipCardBack,
  flipCardFront,
  sectionContent,
  section,
  summaryColor,
  region,
  lang,
  pageData,
  cardTitle,
  mx,
  my,
  ...props
}) => {
  // const isMobile = useMediaQuery({ maxWidth: 960 });
  const [flipped, setIsFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const flipTheCard = isFront => {
    setIsFlipped(isFront);
  };

  const router = useRouter();
  const ritualv1Url = router.asPath.includes('/rituals-v1');
  let cardflag =  section?.cardStyle || section?.style ;
  if (cardflag == 'homepage_sadhana_yellow' || cardflag == 'yellow' || cardflag == 'sadhana_yellow'){
    cardflag = 'sadhana';
  }else if (cardflag == 'green' || cardflag == 'homepage_events_green' || cardflag == 'events_green'){
    cardflag = 'events';
  }
  else if (cardflag == 'red' || cardflag == 'homepage_rituals_red' || cardflag == 'rituals_bring_devi_home_red'){
    cardflag = 'rituals';
  }

  useEffect(() => {
    if (flipped) {
      setIsFlipped(false);
    }
  }, [router.asPath]);

  const cardStyle = {
    homepage_sadhana_yellow: '#D09700',
    yellow: '#D09700',
    red: '#BC4034',
    green: '#366445',
    sadhana_yellow: '#D09700',
    homepage_rituals_red: '#BC4034',
    rituals_bring_devi_home_red: '#BC4034',
    events_green: '#366445',
    homepage_events_green: '#366445',
  };

  // console.log('return', sectionContent);
  return (
    <>
      <Box
        // className={classes.flip_card}
        className={`flip_card_ ${isHovered ? 'hovered' : ''}`} 
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)}
        bg="transparent"
        width={{ base: 'min(95%, 380px) !important', md: '380px' }}
        height={{ base: 374, md: 450 }}
        style={{ perspective: '1000px' }}
        {...props}
        mx={mx}
        my={my}
      >
        <Box
          className={classes.flip_card_inner}
          position="relative"
          width="100%"
          height="100%"
          textAlign="center"
          transition={flipped ? 'transform 1.5s' : '.5s ease'}
          style={{ transformStyle: 'preserve-3d' }}
          transform={flipped ? 'rotateY(180deg)' : 'unset'}
          boxShadow="0 4px 8px 0 rgba(0,0,0,0.2)"
          //background="#F3F0E6"
        >
          <Box
            className={classes.flip_card_front}
            p="0px"
            m="0px"
            cursor="pointer"
            onClick={() => flipTheCard(true)}
            // display={flipped ? 'none' : 'block'}
            // background="#F3F0E6"
            zIndex="997"
          >
            {flipCardFront || (
              <>
                <ProBgImg
                  w="100%"
                  mr={{ base: '14px', md: '34px' }}
                  height="100%"
                  position="relative"
                  backgroundPosition="center"
                  style={{
                    backgroundSize: 'cover',
                    backfaceVisibility: 'hidden',
                  }}
                  // background={`linear-gradient(349.95deg, rgba(0, 0, 0, 0.64) 12.65%, rgba(42, 30, 0, 0) 56.89%, rgba(0, 0, 0, 0.24) 97.6%), white`}
                  background={`linear-gradient(349.95deg, rgba(0, 0, 0, 0.8) 12.65%, rgba(0, 0, 0, 0) 62.71%, rgba(0, 0, 0, 0.24) 92.31%)`}
                  img={
                    sectionContent?.cardImage?.url ||
                    sectionContent?.heroImageMobile?.url ||
                    sectionContent?.image?.url
                  }
                  alt={sectionContent?.title}
                  ishover={isHovered}
                >
                  <Flex
                    justifyContent="space-between"
                    px={{ base: '18px', md: '20px' }}
                    pt={{ base: '18px', md: '20px' }}
                  >
                    <Box flex={1} w="50%">
                      {sectionContent?.availableOnline && (
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          zIndex="99"
                          position="relative"
                          // pos="absolute"
                          bg="rgba(0, 0, 0, 0.6)"
                          flexDir="row"
                          display={
                            sectionContent?.availableOnline ? 'block' : 'none'
                          }
                          height="18px"
                          maxH="auto"
                          width="fit-content"
                          // top={{ base: '18px', md: '22px' }}
                          // left={{ base: '18px', md: '20px' }}
                          padding="6px"
                        >
                          <Flex
                            w="max-content"
                            h="100%"
                            justifyContent="center"
                            alignItems="center"
                            style={{ gap: '3.6px' }}
                          >
                            <Box
                              display="inline-block"
                              rounded="full"
                              maxW="8.1px"
                              width="8.1px"
                              height="8.1px"
                              background="#99FA91"
                            ></Box>
                            <Box
                              as="span"
                              fontFamily="FedraSansStd-book"
                              fontSize="10px"
                              letterSpacing="1.5px"
                              textTransform="uppercase"
                              color="#F3F0E6"
                              ml="2px"
                              p="1px 0 0"
                            >
                              {sectionContent?.availableLearnOnlineDisplayText ||
                                pageData?.availableLearnOnlineDisplayText ||
                                'Offer Online'}
                            </Box>
                          </Flex>
                        </Flex>
                      )}
                    </Box>
                    <Box flex={1} w="50%">
                      {sectionContent?.tags && (
                        <Flex
                          flexDir="column"
                          // pos="absolute"
                          // margin-top="20px"
                          // margin-right="20px"
                          zIndex="99"
                          position="relative"
                          width="auto"
                          // pos="relative"
                          height="auto"
                          alignItems="flex-end"
                          // zIndex={10}
                        >
                          {sectionContent?.tags.split(',', 3).map(tag => (
                            <Box
                              key={nanoid()}
                              textTransform="uppercase"
                              color="#FFFFFF"
                              p={{ base: '2px 5.4px', md: '2px 6px' }}
                              fontSize={{ base: '10px', md: '12px' }}
                              lineHeight="17px"
                              background="primary.main"
                              mb="10px"
                              fontFamily="FedraSansStd-book, sans-serif"
                              textAlign="right"
                            >
                              {tag}
                            </Box>
                          ))}
                        </Flex>
                      )}
                    </Box>
                  </Flex>

                  <Box>
                    <Flex
                      position="absolute"
                      bottom="0px"
                      left="0px"
                      right="-2px"
                      width="100%"
                      flexDir="row"
                      height="100%"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      background="linear-gradient(349.95deg, rgba(0, 0, 0, 0.8) 12.65%, rgba(0, 0, 0, 0) 62.71%, rgba(0, 0, 0, 0.24) 92.31%)"
                    >
                      <Box
                        pl={{ base: '17px', md: '20px' }}
                        maxW="93%"
                        pb={{ base: '13.18px', md: '20px' }}
                      >
                        <Text
                          fontSize={{ base: 'h5', md: 'h4' }}
                          as="h4"
                          color="title.light"
                          lineHeight={{ base: '48.6px', md: '54px' }}
                          fontFamily="FedraSerifDisplay"
                          textAlign="left"
                        >
                          {sectionContent?.title}
                        </Text>
                        <Text
                          color="#EBDEDE"
                          fontSize={{ base: '10px', md: '12px' }}
                          fontFamily="FedraSansStd-Book"
                          textAlign="left"
                          mt="14px"
                          minH="16px"
                          display="flex"
                          flexDir="row"
                          flexWrap="nowrap"
                          maxW="85%"
                          textTransform="uppercase"
                        >
                          {sectionContent?.locations?.length > 0 && (
                            <Box>
                              <TiLocationOutline
                                style={{
                                  color: '#DF6F3B',
                                  display: 'inline',
                                  fontSize: '20px',
                                  marginTop: '-2px',
                                }}
                              />
                            </Box>
                          )}
                          <Text
                            as="span"
                            maxW={{ base: '180px', sm: '250px', md: '270px' }}
                          >
                            {sectionContent?.locations?.map((loc, index) => {
                              return (
                                index < 3 && (
                                  <Box as="span" key={index}>
                                    {loc?.title || loc}
                                    {index <
                                    sectionContent?.locations.length - 1
                                      ? ','
                                      : ''}
                                    &nbsp;
                                  </Box>
                                )
                              );
                            })}
                            {sectionContent.locations?.length > 3 && (
                              <Box as="span">
                                + {sectionContent.locations?.length - 3}{' '}
                                LOCATIONS
                              </Box>
                            )}
                          </Text>
                        </Text>
                      </Box>
                      <Box
                        alignSelf="flex-end"
                        padding={0}
                        margin="0"
                        position="absolute"
                        right="-0.5px"
                        bottom="-0.5px"
                        onClick={() => {
                          flipTheCard(true);
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: 'ritual_card_click',
                            ritual: sectionContent?.title,
                          });
                        }}
                      >
                        <FlagButton
                          bgColor={
                            cardStyle[section?.cardStyle || section?.style]
                          }
                          width={{ base: '66px', md: '74px' }}
                          height={{ base: '76px', md: '84px' }}
                          content={<RightArrow />}
                          top="60%"
                          position="absolute"
                          right="15%"
                          flag={cardflag}
                          ishover={isHovered}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </ProBgImg>
              </>
            )}
          </Box>

          <Box
            className={classes.flip_card_back}
            position="relative"
            // display={flipped ? 'block' : 'none'}
            background="#F3F0E6"
            _hover={{
              bg: '#ffffff'
            }}
            zIndex="999"
          >
            {flipCardBack || (
              <Box
                textAlign="left"
                width="100%"
                padding="30px"
                height="100%"
                display="flex"
                justifyContent="space-between"
                flexDir="column"
                // background="#F3F0E6"
                // onClick={e => isMobile && flipTheCard(false)}
                onClick={() => ritualv1Url ? flipTheCard(false) : ''}
              >
                <Box>
                  <Text
                    as="h5"
                    fontFamily="fedraSerifDisplay"
                    fontSize={{ base: '32px', md: 'h5' }}
                    lineHeight={{ base: '35px', md: '52px' }}
                    fontWeight="500"
                    fontStyle="normal"
                    color="headingText.dark"
                  >
                    {sectionContent?.title}
                  </Text>
                  <Text
                    fontFamily="'FedraSerifAStdBook'"
                    fontSize={{ base: '14px', md: 'body2' }}
                    lineHeight={{ base: '21.5px', md: '24px' }}
                    fontWeight="500"
                    mt={{ base: '12px', md: '8px' }}
                    fontStyle="normal"
                    color={summaryColor || 'bodyText.color1'}
                    className={classes.cardDes}
                  >
                    {sectionContent?.summary}
                  </Text>
                </Box>
                {(sectionContent.urlAlias || sectionContent.url) && (
                  <Box>
                    <NextLink
                      href={refineUrlDomain(
                        sectionContent?._modelApiKey === 'linga_bhairavi_ritual'
                          ? `rituals/${sectionContent.urlAlias}`
                          : sectionContent.urlAlias || sectionContent.url,
                        region,
                        lang,
                        ritualv1Url?cardTitle:sectionContent?._modelApiKey === 'linga_bhairavi_ritual'?cardTitle:''
                      )}
                      passHref
                      legacyBehavior
                    >
                      <Link
                        mx="auto"
                        bottom="0"
                        right="0"
                        variant="primary"
                        textDecoration="none"
                        _hover={{ textDecoration: 'none' }}
                        _focus={{ textDecoration: 'none' }}
                        _active={{ textDecoration: 'none' }}
                        w="100%"
                        // target='_blank'
                      >
                        <Button
                          variant="primary"
                          _hover={{ textDecoration: 'none', bg:'#F7A15E' }}
                          _focus={{ textDecoration: 'none', bg:'#F7A15E' }}
                          _active={{ textDecoration: 'none', bg:'#F7A15E' }}
                          size={{ base: 'lg', md: 'lg' }}
                          fontWeight="300"
                          fontSize="body2"
                          letterSpacing=".9px"
                          w={{ base: '152px', md: '152px' }}
                          h={{ base: '42px', md: '42px' }}
                          onClick={() => {
                          if(ritualv1Url || sectionContent?._modelApiKey == 'linga_bhairavi_ritual'){
                            const Mixpanel = mixPanel(router.asPath);
                            // Track events for know more button
                            Mixpanel.track('lb_ritual_selected', {
                              ritual: {
                                ritual_name:sectionContent?.title,
                                selection_source: cardTitle,
                              }
                            });
                          }else{
                             window.dataLayer = window.dataLayer || [];
                             window.dataLayer.push({
                              event: 'ritual_know_more',
                              ritual: sectionContent?.title,
                            });
                          }
                          }}
                        >
                          Know More
                        </Button>
                      </Link>
                    </NextLink>
                  </Box>
                )}
              </Box>
            )}
            <Box
              position="absolute"
              bottom="0"
              right="0"
              onClick={() => flipTheCard(false)}
            >
              <FlagButton
                bgColor={cardStyle[section?.cardStyle || section?.style]}
                content={<CloseIcon />}
                top="54%"
                width={{ base: '66px', md: '74px' }}
                height={{ base: '76px', md: '84px' }}
                position="absolute"
                right="15%"
                flag={cardflag}
                ishover={isHovered}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FlipCard;

const RightArrow = () => {
  return (
    <>
      <svg
        width="27"
        height="16"
        viewBox="0 0 27 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 15.001L25.5 8.00098M25.5 8.00098L18.5 1.00098M25.5 8.00098L1 8.00098"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

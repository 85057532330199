/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { TbExclamationMark } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Services */
/* Styles */
import styles from '@lb/components/Message/Message.module.css';

/**
 * Renders LBContactUsCta Component
 *
 * @param {sectionContent} sectionContent
 * @returns {ReactElement} LBContactUsCta Message
 */

const LBContactUsCta = ({ sectionContent, ...props }) => {
  // console.log('LBContactUsCta', sectionContent);

  return (
    <>
      <Box
        maxW={601}
        w="100%"
        mx={{ base: '10px', md: 'auto' }}
        mb={{ base: '0px', md: '30px' }}
        mt="20px"
        display="flex"
        {...props}
      >
        <Box maxW={570} pt="17px">
          <Box
            display="flex"
            border="2px solid #823333"
            borderRadius="50%"
            // fontSize={{ base: '20px', md: '30px' }}
            w="20px"
            h="20px"
            color="#823333"
            fontFamily="FedraSansStd-Book"
            bg="#FFCB66"
            textAlign="center"
            alignContent="center"
            justifyContent="center"
            transform={{ base: 'rotate(0deg)', md: 'rotate(180deg)' }}
          >
            {/* <AiFillExclamationCircle fontSize="inherit" color="#FFCB66" /> */}
            <TbExclamationMark />
          </Box>
        </Box>
        <Box maxW="90%">
          <Box
            as="h3"
            fontSize={{ base: '20px', md: '26px' }}
            color="#823333"
            fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
            lineHeight={{ base: '28px', md: '31px' }}
            // maxW={{ base: 'auto', md: '90%' }}
            p={{ base: '12px 0 0 15px', md: '12px 0 0 15px' }}
            fontWeight={{ base: '500', md: '600' }}
          >
            {sectionContent?.title}
          </Box>
          <Box>
            <StructuredTextParser
              mt="8px"
              str={render(sectionContent?.description)}
              className={styles.message}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LBContactUsCta;
